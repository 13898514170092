/** @jsx jsx */

import { jsx, Box, Heading } from 'theme-ui';
import { HeaderSpacing } from "../../components/page"
import { useTranslation, Trans } from 'react-i18next';
import { PageRoot } from '../../data/content';
import SEO from '../../components/seo';
import { Fragment } from "react";
import SplitPage from "../../components/split-page";
import { Dot } from '../../components/content-page';

const JoinPage: PageRoot<{}> = ({ location: { pathname } }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <SEO
        path={pathname}
        title={'Enlist your organisation'}
      />
      <SplitPage
        aside={
          <Fragment>
            <HeaderSpacing />
            <Heading variant='headings.1'><Trans>
              Apply for your organisation to become a Member of the Progressive International
            </Trans></Heading>
            <Box sx={{ display: ['none', 'block'], mt: '4em', mb: '1em' }}>
              <Dot size={[75, 75, 100]} />
            </Box>
          </Fragment>
        }
        main={
          <Fragment>
            <HeaderSpacing sx={{ display: ['none', null, 'block'] }} />
            <a href="mailto:info@progressive.international">Email us to Join</a>
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default JoinPage