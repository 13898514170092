/** @jsx jsx */
import { jsx, SxProps, Box, Container, Flex } from 'theme-ui';
const bg = require("../images/dymaxion-dark-background.png")

const SplitPage: React.FC<{
  aside: any
  sxAside?: SxProps['sx']
  main: any
  sxMain?: SxProps['sx']
  minorWidth?: number
  majorWidth?: number
  bgOverride?: any
}> = ({ aside, main, sxAside = {}, sxMain = {}, bgOverride }) => {
  let bgFinal = bg
  if (bgOverride) {
    bgFinal = bgOverride
  }
  return (
    <Box>
      <Box
        sx={{
          display: ['none', 'none', 'block'],
          zIndex: 0,
          top: 0,
          left: 0,
          position: ['relative', null, 'sticky'],
          zIndex: 0,
          width: ['100%', null, '50%'],
          height: [300, null, '100vh'],
          top: 0,
          left: 0,
          bg: 'black',
          backgroundColor: '#280000',
          backgroundImage: "url('" + bgFinal + "')",
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      />
      <Container>
        <Flex
          sx={{
            position: 'relative',
            top: 0,
            zIndex: 10,
            flexDirection: ['column', null, 'row'],
            flexWrap: 'nowrap',
            position: 'relative',
            marginTop: [null, null, '-100vh']
          }}
        >
          <Box sx={{
            width: ['100%', null, '50%'],
            color: 'white',
            bg: ['black', null, 'transparent'],
            backgroundColor: ['#280000', null, 'transparent'],
            backgroundImage: ["url('" + bg + "')", null, 'none'],
            backgroundSize: ["cover", null, 'none'],
            backgroundPosition: ["center", null, 'none']
          }}>
            <Box sx={{
              px: 4,
              py: [4, 4, 6],
              top: 0,
              position: 'sticky',
              ...sxAside
            }}>
              {aside}
            </Box>
          </Box>
          <Box sx={{ px: [4, 4, 6, 8], py: [2, null, 6], width: ['100%', null, '50%'], ...sxMain }}>
            {main}
          </Box>
        </Flex>
      </Container>
    </Box >
  )
}

export default SplitPage